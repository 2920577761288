<template>
  <div class="titulo">
    <div class="margem container">
      <h2>Gestão</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeGestao",
  components: {},
  data() {
    return {};
  },
};
</script>
