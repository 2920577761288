<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">Concluir Alerta</v-card-title>
      <v-card-text>
        <textarea v-model="obsResolvedor" placeholder="Observação" rows="5" @keyup.enter="submitConclusao"></textarea>
      </v-card-text>
      <v-spacer></v-spacer>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="closeDialog">Cancelar</button>
        <button class="acao-secundaria" @click="submitConclusao">Concluir</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Alertas</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="tags m-b">
        <a v-for="tabItem in tabItems" :key="tabItem.value" :class="{ ativo: tab === tabItem.value, active: tab === tabItem.value }" @click.prevent="changeTab(tabItem.value)">
          {{ tabItem.text }}
        </a>
      </div>

      <div v-for="tabItem in tabItems" :key="tabItem.value">
        <div v-if="tab === tabItem.value">
          <table class="tabela">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Notificante</th>
                <th>Motivo</th>
                <th>Observação</th>
                <th v-if="tab === 0"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in paginatedItems" :key="item.id">
                <td>{{ item.ativo_codigo }}</td>
                <td>{{ item.ativo_descricao }}</td>
                <td>{{ item.notificante_nome }}</td>
                <td>{{ item.motivo_nome }}</td>
                <td>{{ item.obsNotificante }}</td>
                <td v-if="tab === 0">
                  <button class="acao-secundaria" @click="concluirAlerta(item)">Concluir</button>
                </td>
              </tr>
            </tbody>
          </table>

          <nav v-if="tab === tabItem.value" class="ml-3" aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" href="#" @click.prevent="nextPage">Próxima</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { getlistarAlertas, atualizarAlertas } from "@/services/api";

import { sso } from "roboflex-thalamus-sso-lib";
export default {
  name: "AlertasView",
  data() {
    return {
      items: [],
      search: "",
      perPage: 10,
      currentPage: 1,
      tab: 0,
      tabItems: [
        { text: "Pendentes", value: 0, tipo: "pendentes" },
        { text: "Concluídas", value: 1, tipo: "concluidas" },
      ],
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      dataLoaded: [false, false],
      dialog: false,
      obsResolvedor: "",
      alertaSelecionado: null,
      usuario_id: null,
    };
  },
  computed: {
    filteredItems() {
      const currentTabItems = this.items.filter((item) => item.tipo === this.tabItems[this.tab].tipo);
      if (this.search) {
        const searchLower = this.search.toLowerCase();
        return currentTabItems.filter(
          (item) =>
            item.ativo_codigo.toString().includes(this.search) ||
            (item.ativo_descricao && item.ativo_descricao.toLowerCase().includes(searchLower)) ||
            (item.notificante_nome && item.notificante_nome.toLowerCase().includes(searchLower)) ||
            (item.motivo_nome && item.motivo_nome.toLowerCase().includes(searchLower)) ||
            (item.obsNotificante && item.obsNotificante.toLowerCase().includes(searchLower))
        );
      }
      return currentTabItems;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.perPage);
    },
  },
  watch: {
    tab(newTab, oldTab) {
      if (newTab !== oldTab) {
        this.currentPage = 1;
        if (!this.dataLoaded[newTab]) {
          this.getData(this.tabItems[newTab].tipo);
        }
      }
    },
    search() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.getData(this.tabItems[this.tab].tipo);
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },
  methods: {
    async getData(tipo) {
      try {
        const response = await getlistarAlertas({ tipo });
        this.items = response.data.map((item) => ({ ...item, tipo }));
        this.dataLoaded[this.tab] = true;
      } catch (error) {
        console.error("Erro ao carregar alertas:", error);
      }
    },
    changeTab(newTab) {
      this.tab = newTab;
      this.getData(this.tabItems[newTab].tipo);
    },
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    concluirAlerta(alerta) {
      this.alertaSelecionado = alerta;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.obsResolvedor = "";
      this.alertaSelecionado = null;
    },
    async submitConclusao() {
      try {
        const payload = {
          notificacao_id: this.alertaSelecionado.id,
          resolvedor_id: this.usuario_id,
          obsResolvedor: this.obsResolvedor,
        };
        await atualizarAlertas(payload);
        this.closeDialog();
        this.showSnackbar("Alerta concluído com sucesso", "success");
        this.getData(this.tabItems[this.tab].tipo);
      } catch (error) {
        console.error("Erro ao concluir alerta:", error);
        this.showSnackbar("Erro ao concluir alerta", "error");
      }
    },
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  gap: 1rem;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}
.pagination .page-item {
  margin-right: 0.5rem;
}
.pagination .page-link {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ddd;
  cursor: pointer;
}
.pagination .page-item.disabled .page-link {
  cursor: not-allowed;
  background-color: #eee;
}
</style>
