<template>
  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" @input="filterItems" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Minha Área</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="tags m-b">
        <a href="#" :class="{ ativo: selectedTab === 0, active: selectedTab === 0 }" @click.prevent="changeTab(0)">Ativos</a>
        <a href="#" :class="{ ativo: selectedTab === 1, active: selectedTab === 1 }" @click.prevent="changeTab(1)">Solicitações</a>
      </div>

      <AtivosComponent v-show="selectedTab === 0" v-if="selectedTab === 0" ref="AtivosComponent" :search="search" @update:search="updateSearch" />
      <SolicitacaoComponent v-show="selectedTab === 1" v-if="selectedTab === 1" ref="SolicitacaoComponent" :search="search" @update:search="updateSearch" />
    </div>
  </div>
</template>

<script>
import SolicitacaoComponent from "@/components/MinhaArea/SolicitacaoComponent.vue";
import AtivosComponent from "@/components/MinhaArea/AtivosComponent.vue";

export default {
  name: "MinhaAreaView",
  components: {
    SolicitacaoComponent,
    AtivosComponent,
  },
  data() {
    return {
      selectedTab: 0,
      search: "",
    };
  },
  methods: {
    changeTab(newTab) {
      this.selectedTab = newTab;
      this.$nextTick(() => {
        this.filterItems();
        switch (newTab) {
          case 0:
            if (this.$refs.AtivosComponent) {
              this.$refs.AtivosComponent.loadData();
            }
            break;
          case 1:
            if (this.$refs.SolicitacaoComponent) {
              this.$refs.SolicitacaoComponent.loadData();
            }
            break;
        }
      });
    },
    filterItems() {
      const currentComponent =
        this.$refs[
          {
            0: "AtivosComponent",
            1: "SolicitacaoComponent",
          }[this.selectedTab]
        ];

      if (currentComponent && currentComponent.filterItems) {
        currentComponent.filterItems();
      }
    },
    updateSearch(newSearch) {
      this.search = newSearch;
      this.filterItems();
    },
  },
};
</script>
