<template>
  <form @submit.prevent="enviarForm">
    <div class="linha grind-4">
      <div class="coluna">
        <input type="text" v-model="nome" id="nome" placeholder="Local Físico" required />
      </div>

      <div class="coluna">
        <select v-model="local_id" required>
          <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
            {{ unidade.local_nome }}
          </option>
        </select>
      </div>

      <div class="coluna">
        <select v-model="setor_id" required>
          <option v-for="setor in setores" :key="setor.id" :value="setor.id">
            {{ setor.nome }}
          </option>
        </select>
      </div>

      <button class="acao-secundaria" type="submit">Adicionar</button>
    </div>
  </form>
  <br />
  <table class="tabela">
    <thead>
      <tr>
        <th>Local Físico</th>
        <th>Unidade</th>
        <th>Setor Responsável</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in paginatedItems" :key="item.id">
        <td>
          <input type="text" v-model="item.nome" />
        </td>
        <td>
          <select v-model="item.local_id">
            <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
              {{ unidade.local_nome }}
            </option>
          </select>
        </td>
        <td>
          <select v-model="item.setor_id">
            <option v-for="setor in setores" :key="setor.id" :value="setor.id">
              {{ setor.nome }}
            </option>
          </select>
        </td>
        <td>
          <div class="linha alinha-v alinha-centro">
            <button class="acao-secundaria" @click="atualizarLocalFisico(item)">Atualizar</button>
            <button class="acao-secundaria" @click="excluirItem(item.id)">Excluir</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
  </div>

  <confirmation-dialog v-model="dialogExcluir" title="Confirmar Exclusão" message="Você tem certeza que deseja excluir este item?" @confirm="confirmarExclusao" @cancel="dialogExcluir = false" />

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { getLocalFisico, gravarLocalFisico, excluirLocalFisico, atualizarLocalFisico, getSetor, getUnidade } from "../../services/api";
import ConfirmationDialog from "../ConfirmationDialog.vue";

export default {
  name: "LocaisComponent",

  components: {
    ConfirmationDialog,
  },

  props: {
    search: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      dialogExcluir: false,
      itemParaExcluir: null,
      items: [],
      setores: [],
      unidades: [],
      nome: "",
      setor_id: "",
      local_id: "",
      localSearch: "",
      snackbarVisible: false,
      snackbarColor: "success",
      snackbarText: "",
      currentPage: 1,
      itemsPerPage: 10,
      dadosCarregados: false,
    };
  },

  watch: {
    search(val) {
      this.localSearch = val;
      this.filterItems();
    },
  },

  computed: {
    filteredItems() {
      if (this.localSearch) {
        return this.items.filter((item) => {
          return (
            item.nome.toLowerCase().includes(this.localSearch.toLowerCase()) ||
            this.unidades.some((unidade) => unidade.id === item.local_id && unidade.local_nome.toLowerCase().includes(this.localSearch.toLowerCase())) ||
            this.setores.some((setor) => setor.id === item.setor_id && setor.nome.toLowerCase().includes(this.localSearch.toLowerCase()))
          );
        });
      }
      return this.items;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      Promise.all([this.getLocalFisico(), this.getSetor(), this.getUnidade()])
        .then(() => {
          this.dadosCarregados = true;
        })
        .catch((error) => {
          console.error("Erro ao carregar dados:", error);
        });
    },

    filterItems() {},

    emitSearch() {
      this.$emit("update:search", this.localSearch);
    },

    showSnackbar(message, color = "success") {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => (this.snackbarVisible = false), 3000);
    },

    excluirItem(itemId) {
      this.itemParaExcluir = itemId;
      this.dialogExcluir = true;
    },

    confirmarExclusao() {
      if (this.itemParaExcluir !== null) {
        excluirLocalFisico(this.itemParaExcluir)
          .then(() => {
            this.items = this.items.filter((item) => item.id !== this.itemParaExcluir);
            this.dialogExcluir = false;
            this.itemParaExcluir = null;
            this.showSnackbar("Item excluído!", "success");
          })
          .catch((error) => {
            console.error("Erro ao excluir o item:", error);
          });
      }
    },

    enviarForm() {
      const formValido = this.nome && this.setor_id && this.local_id;
      if (formValido && this.dadosCarregados) {
        const dadosFormulario = {
          nome: this.nome,
          setor_id: this.setor_id,
          unidade_id: this.local_id,
        };
        gravarLocalFisico(dadosFormulario)
          .then((response) => {
            const novoItem = response.data;
            this.items.unshift(novoItem);
            this.nome = "";
            this.setor_id = "";
            this.local_nome = "";
            this.showSnackbar("Item cadastrado!", "success");
          })
          .catch((error) => {
            console.error("Erro ao enviar formulário:", error);
            this.showSnackbar("Erro ao cadastrar item", "error");
          });
      } else if (!this.dadosCarregados) {
        this.showSnackbar("Aguarde o carregamento dos dados", "warning");
      }
    },

    getLocalFisico() {
      return getLocalFisico()
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },

    getUnidade() {
      return getUnidade()
        .then((response) => {
          this.unidades = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },

    getSetor() {
      return getSetor()
        .then((response) => {
          this.setores = response.data;
        })
        .catch((error) => {
          console.error("Error fetching setores:", error);
        });
    },

    atualizarLocalFisico(item) {
      const dadosAtualizados = {
        id: item.id,
        nome: item.nome,
        setor_id: item.setor_id,
        unidade_id: item.local_id,
      };
      atualizarLocalFisico(dadosAtualizados.id, dadosAtualizados)
        .then(() => {
          this.showSnackbar("Item atualizado!", "success");
        })
        .catch((error) => {
          console.error("Erro ao atualizar o item:", error);
          this.showSnackbar("Erro ao atualizar o item", "error");
        });
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
