<template>
  <v-dialog v-model="dialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
      <v-card-text>{{ dialogText }}</v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="dialogVisible = false">Cancelar</button>
        <button class="acao-secundaria" @click="executeAction">Confirmar</button>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="approvalDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">Aprovar Material</v-card-title>
      <v-card-text>
        <div>
          <label for="qtSolicitada">Quantidade solicitada</label>
          <input id="qtSolicitada" type="number" v-model="selectedMaterial.qt" readonly />
          <label for="qtAprovada">Quantidade a ser aprovada</label>
          <input id="qtAprovada" type="number" v-model="qtAprovada" min="1" />
        </div>
        <div>
          <label for="approvalObservation">Observação</label>
          <textarea id="approvalObservation" v-model="approvalObservation" rows="3" required @keyup.enter="approveWithObservation"></textarea>
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="approvalDialogVisible = false">Cancelar</button>
        <button class="acao-secundaria" @click="approveWithObservation">Aprovar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="rejectionDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">Reprovar Material</v-card-title>
      <v-card-text>
        <v-textarea v-model="rejectionObservation" label="Observação" rows="3" required @keyup.enter="rejectWithObservation"></v-textarea>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="rejectionDialogVisible = false">Cancelar</button>
        <button class="acao-secundaria" @click="rejectWithObservation">Reprovar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="detailsDialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">Detalhes da Solicitação</v-card-title>
      <v-card-text>
        <div v-if="solicitacaoDetalhes">
          <p><strong>Código:</strong> {{ solicitacaoDetalhes.codigo }}</p>
          <p>
            <strong>Finalidade:</strong>
            {{ solicitacaoDetalhes.finalidade }}
          </p>
          <p>
            <strong>Solicitante:</strong>
            {{ solicitacaoDetalhes.solicitante_nome }}
          </p>
          <p>
            <strong>Setor:</strong>
            {{ solicitacaoDetalhes.setor_id }}
          </p>
          <p>
            <strong>Data da solicitação:</strong>
            {{ formatarData(solicitacaoDetalhes.dtCriacao) }}
          </p>
          <p>
            <strong>Justificativa:</strong>
            {{ solicitacaoDetalhes.justificativa }}
          </p>
          <v-divider></v-divider>
          <h4>Material</h4>
          <v-list dense>
            <v-list-item v-for="material in solicitacaoDetalhes.materiais" :key="material.solicitacaoMaterial_id">
              <v-list-item-content>
                <v-list-item-title>{{ material.material_desc }}</v-list-item-title>
                <v-list-item-subtitle>Quantidade: {{ material.qt }}</v-list-item-subtitle>
              </v-list-item-content>

              <h6>
                <v-list dense v-if="material.obsAprovacao"> Observação: {{ material.obsAprovacao }} </v-list>

                <v-list dense v-if="material.qtAprovada"> Quantidade Aprovada: {{ material.qtAprovada }} </v-list>
              </h6>
            </v-list-item>
          </v-list>
        </div>
        <div v-else>
          <p>Carregando...</p>
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button class="acao-secundaria" @click="detailsDialogVisible = false">Fechar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Aprovação de Material</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="tags m-b">
        <div v-for="tabItem in tabItems" :key="tabItem.value">
          <a :class="{ ativo: tab === tabItem.value, active: tab === tabItem.value }" @click.prevent="tab = tabItem.value">
            {{ tabItem.text }}
          </a>
        </div>
      </div>

      <table class="tabela">
        <thead>
          <tr>
            <th>Código</th>
            <th>Finalidade</th>
            <th>Material</th>
            <th>Qtd Solicitada</th>
            <th v-if="tab === 1">Qtd Aprovada</th>
            <th>Solicitante</th>
            <th v-if="tab === 0">Aprovar</th>
            <th v-if="tab === 0">Reprovar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredItems" :key="item.solicitacaoMaterial_id" @click="openDetailsDialog(item.solicitacao_id, item.solicitacaoMaterial_id)">
            <td>{{ item.codigo }}</td>
            <td>{{ item.finalidade }}</td>
            <td>{{ item.material_desc }}</td>
            <td>{{ item.qt }}</td>
            <td v-if="tab === 1">{{ item.qtAprovada }}</td>
            <td>{{ item.solicitante_nome }}</td>
            <td v-if="tabItems[tab].tipo === 'pendentes' && tab === 0">
              <button @click.stop="openApprovalDialog(item)" class="acao-secundaria">Aprovar</button>
            </td>
            <td v-if="tabItems[tab].tipo === 'pendentes' && tab === 0">
              <button @click.stop="openRejectionDialog(item)" class="acao-secundaria">Reprovar</button>
            </td>
          </tr>
        </tbody>
      </table>

      <nav class="ml-3" aria-label="Page navigation">
        <div class="pagination">
          <div class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
          </div>
          <div class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="changePage(page)">
              {{ page }}
            </a>
          </div>
          <div class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="nextPage">Próxima</a>
          </div>
        </div>
      </nav>

      <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { listarMateriais, alterarStatusMaterial, detalharSolicitacao } from "@/services/api";
import { formatarData } from "@/utils/funcoesUtils";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "AprovacaoView",
  data() {
    return {
      items: [],
      perPage: 10,
      currentPage: 1,
      tab: 0,
      tabItems: [
        { text: "Pendentes", value: 0, tipo: "pendentes" },
        { text: "Aprovados", value: 1, tipo: "aprovados" },
        { text: "Reprovados", value: 2, tipo: "reprovados" },
      ],
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      dialogVisible: false,
      dialogTitle: "",
      dialogText: "",
      actionType: "",
      search: "",
      actionItem: null,
      approvalDialogVisible: false,
      approvalObservation: "",
      rejectionDialogVisible: false,
      rejectionObservation: "",
      detailsDialogVisible: false,
      solicitacaoDetalhes: null,
      dataLoaded: [false, false, false],
      selectedMaterial: {
        qt: 0,
      },
      qtAprovada: 0,
      usuario_id: null,
    };
  },

  computed: {
    filteredItems() {
      const filtered = this.items.filter(
        (item) =>
          item.codigo.toLowerCase().includes(this.search.toLowerCase()) ||
          item.finalidade.toLowerCase().includes(this.search.toLowerCase()) ||
          item.material_desc.toLowerCase().includes(this.search.toLowerCase()) ||
          item.solicitante_nome.toLowerCase().includes(this.search.toLowerCase())
      );
      return filtered.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    totalPages() {
      const filteredLength = this.items.filter(
        (item) =>
          item.codigo.toLowerCase().includes(this.search.toLowerCase()) ||
          item.finalidade.toLowerCase().includes(this.search.toLowerCase()) ||
          item.material_desc.toLowerCase().includes(this.search.toLowerCase()) ||
          item.solicitante_nome.toLowerCase().includes(this.search.toLowerCase())
      ).length;
      return Math.ceil(filteredLength / this.perPage);
    },
  },
  watch: {
    tab(newTab, oldTab) {
      if (newTab !== oldTab) {
        this.currentPage = 1;
        this.dataLoaded[newTab] = false;
        this.getData(this.tabItems[newTab].tipo);
      }
    },
  },
  mounted() {
    this.getData(this.tabItems[this.tab].tipo);
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },
  methods: {
    formatarData,
    getData(tipo) {
      if (!this.dataLoaded[this.tab]) {
        listarMateriais({ tipo })
          .then((response) => {
            const transformedItems = [];
            response.data.forEach((solicitacao) => {
              if (Array.isArray(solicitacao.materiais)) {
                solicitacao.materiais.forEach((material) => {
                  transformedItems.push({
                    ...solicitacao,
                    ...material,
                    solicitacaoMaterial_id: material.solicitacaoMaterial_id,
                    material_desc: material.material_desc,
                    qt: material.qt,
                    aprovado: material.aprovado,
                    separador_id: material.separador_id,
                    dtSeparacao: material.dtSeparacao,
                    obsSeparacao: material.obsSeparacao,
                    receptor_id: material.receptor_id,
                    obsRecebimento: material.obsRecebimento,
                    dtRecebimento: material.dtRecebimento,
                    obsAprovacao: material.obsAprovacao,
                  });
                });
              } else {
                Object.values(solicitacao.materiais).forEach((material) => {
                  transformedItems.push({
                    ...solicitacao,
                    ...material,
                    solicitacaoMaterial_id: material.solicitacaoMaterial_id,
                    material_desc: material.material_desc,
                    qt: material.qt,
                    aprovado: material.aprovado,
                    separador_id: material.separador_id,
                    dtSeparacao: material.dtSeparacao,
                    obsSeparacao: material.obsSeparacao,
                    receptor_id: material.receptor_id,
                    obsRecebimento: material.obsRecebimento,
                    dtRecebimento: material.dtRecebimento,
                    obsAprovacao: material.obsAprovacao,
                  });
                });
              }
            });
            this.items = transformedItems;
            this.dataLoaded[this.tab] = true;
          })
          .catch((error) => {
            console.error("Erro ao buscar dados:", error);
            this.dataLoaded[this.tab] = false;
          });
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    confirmAction(type, item) {
      this.actionType = type;
      this.actionItem = item;
      if (type === "aprovar") {
        this.openApprovalDialog(item);
      } else if (type === "reprovar") {
        this.openRejectionDialog(item);
      }
      this.dialogVisible = false;
    },
    openApprovalDialog(item) {
      this.selectedMaterial = item;
      this.actionItem = item;
      this.qtAprovada = item.qt;
      this.approvalObservation = "";
      this.approvalDialogVisible = true;
    },
    openRejectionDialog(item) {
      this.actionItem = item;
      this.rejectionObservation = "";
      this.rejectionDialogVisible = true;
    },
    approveWithObservation() {
      this.aprovarMaterial(this.actionItem, this.approvalObservation, this.qtAprovada);
      this.approvalDialogVisible = false;
    },
    rejectWithObservation() {
      this.reprovarMaterial(this.actionItem, this.rejectionObservation);
      this.rejectionDialogVisible = false;
    },
    executeAction() {
      if (this.actionType === "aprovar") {
        this.openApprovalDialog(this.actionItem);
      } else if (this.actionType === "reprovar") {
        this.openRejectionDialog(this.actionItem);
      }
      this.dialogVisible = false;
    },
    aprovarMaterial(item, observacao, qtAprovada) {
      if (!item || !item.solicitacaoMaterialStatus_id) {
        console.error("Item inválido", item);
        return;
      }
      const data = {
        solicitacaoMaterialStatus_id: item.solicitacaoMaterialStatus_id,
        aprovado: 1,
        aprovador_id: this.usuario_id,
        qtAprovada: qtAprovada,
        obsAprovacao: observacao,
      };

      alterarStatusMaterial(data)
        .then(() => {
          this.removeMaterialFromPending(item.solicitacao_id, item.solicitacaoMaterial_id);
          this.showSnackbar("Material aprovado com sucesso!", "success");
        })
        .catch(() => {
          this.showSnackbar("Erro ao aprovar o material", "error");
        });
    },
    reprovarMaterial(item, observacao) {
      if (!item || !item.solicitacaoMaterialStatus_id) {
        console.error("Item inválido", item);
        return;
      }
      const data = {
        solicitacaoMaterialStatus_id: item.solicitacaoMaterialStatus_id,
        aprovado: 0,
        aprovador_id: this.usuario_id,
        obsAprovacao: observacao,
      };

      alterarStatusMaterial(data)
        .then(() => {
          this.removeMaterialFromPending(item.solicitacao_id, item.solicitacaoMaterial_id);
          this.showSnackbar("Material reprovado com sucesso!", "success");
        })
        .catch(() => {
          this.showSnackbar("Erro ao reprovar o material", "error");
        });
    },
    removeMaterialFromPending(solicitacaoId, materialId) {
      const itemIndex = this.items.findIndex((m) => m.solicitacao_id === solicitacaoId && m.solicitacaoMaterial_id === materialId);
      if (itemIndex !== -1) {
        this.items.splice(itemIndex, 1);
      }
    },
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
    },
    openDetailsDialog(solicitacao_id, solicitacaoMaterial_id) {
      this.detailsDialogVisible = true;
      detalharSolicitacao({ solicitacao_id, solicitacaoMaterial_id })
        .then((response) => {
          this.solicitacaoDetalhes = response.data[0];
        })
        .catch((error) => {
          console.error("Erro ao buscar detalhes da solicitação:", error);
          this.solicitacaoDetalhes = null;
        });
    },
  },
};
</script>
