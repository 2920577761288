<template>
  <v-dialog v-model="dialogVisible" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="cancel">Cancelar</v-btn>
        <v-btn color="primary" text @click="confirm">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: "Confirmar" },
    message: { type: String, default: "Você tem certeza?" },
  },
  data() {
    return {
      dialogVisible: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.dialogVisible = false;
    },
    cancel() {
      this.$emit("cancel");
      this.dialogVisible = false;
    },
  },
};
</script>
