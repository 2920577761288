import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib";

const link = "https://portal.thalamus.ind.br/#/";
const linkHomologacao = "http://portal-homologacao.thalamus.ind.br/#/";
const usuarioLogado = sso.getUsuarioLogado();

const moduloService = {
  buscarModulos: async () => {
    var resp = await api.post("funcionalidade/cards/usuario", {
      usuario_id: usuarioLogado.id,
    });
    return resp.data;
  },
  linkPortal() {
    return process.env.NODE_ENV == "staging" ||
      process.env.NODE_ENV == "development"
      ? linkHomologacao
      : link;
  },
};

export default moduloService;
