<template>
  <HeaderComponent />
  <main id="main">
    <MenuLateral />
    <section>
      <RouterView />
    </section>
  </main>
</template>

<script>
import MenuLateral from "@/components/MenuLateral.vue";
import HeaderComponent from "./components/HeaderComponent.vue";

export default {
  name: "App",
  components: {
    MenuLateral,
    HeaderComponent,
  },
  computed: {},
};
</script>

<style>
#main {
  display: flex;
}
.snackbar {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
}

a {
  cursor: pointer;
}
.snackbar.success {
  background-color: green;
}

.snackbar.error {
  background-color: red;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
</style>
