import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./assets/css/thalamus.css";

import * as components from "vuetify/components";
import { createVuetify } from "vuetify";

const vuetify = createVuetify({
  components,
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

app.mount("#app");
