<template>
  <form @submit.prevent="enviarForm">
    <div class="linha">
      <div class="coluna">
        <input type="text" v-model="nome" id="nome" placeholder="Unidade" required />
      </div>

      <div class="direita">
        <button class="acao-secundaria" type="submit">Adicionar</button>
      </div>
    </div>
  </form>
  <br />
  <table class="tabela">
    <thead>
      <tr>
        <th>Nome</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in paginatedItems" :key="item.id">
        <td>
          <input type="text" v-model="item.local_nome" />
        </td>
        <td>
          <div class="linha alinha-v alinha-centro">
            <button @click="atualizarUnidade(item)" class="acao-secundaria">Atualizar</button>
            <button @click="excluirItem(item.id)" class="acao-secundaria">Excluir</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
  </div>

  <confirmation-dialog v-model="dialogExcluir" title="Confirmar Exclusão" message="Você tem certeza que deseja excluir este item?" @confirm="confirmarExclusao" @cancel="dialogExcluir = false" />

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { getUnidade, gravarUnidade, excluirUnidade, atualizarUnidade } from "../../services/api";
import ConfirmationDialog from "../ConfirmationDialog.vue";

export default {
  name: "UnidadeComponent",
  components: {
    ConfirmationDialog,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogExcluir: false,
      itemParaExcluir: null,
      items: [],
      nome: "",
      localSearch: "",
      snackbarVisible: false,
      snackbarColor: "success",
      snackbarText: "",
      currentPage: 1,
      itemsPerPage: 10,
    };
  },

  watch: {
    search(val) {
      this.localSearch = val;
      this.filterItems();
    },
  },

  computed: {
    filteredItems() {
      if (this.localSearch) {
        return this.items.filter((item) => {
          return item.local_nome.toLowerCase().includes(this.localSearch.toLowerCase());
        });
      }
      return this.items;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getUnidade();
    },

    filterItems() {},

    emitSearch() {
      this.$emit("update:search", this.localSearch);
    },

    showSnackbar(message, color = "success") {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => (this.snackbarVisible = false), 3000);
    },
    excluirItem(itemId) {
      this.itemParaExcluir = itemId;
      this.dialogExcluir = true;
    },
    confirmarExclusao() {
      if (this.itemParaExcluir !== null) {
        excluirUnidade(this.itemParaExcluir)
          .then(() => {
            this.items = this.items.filter((item) => item.id !== this.itemParaExcluir);
            this.dialogExcluir = false;
            this.itemParaExcluir = null;
            this.showSnackbar("Item excluído!", "success");
          })
          .catch((error) => {
            console.error("Erro ao excluir o item:", error);
          });
      }
    },
    async enviarForm() {
      if (this.nome) {
        const dadosFormulario = { local_nome: this.nome };
        try {
          const response = await gravarUnidade(dadosFormulario);
          const novoItem = response.data;
          this.items.unshift(novoItem);
          this.nome = "";
          this.showSnackbar("Item cadastrado!", "success");
        } catch (error) {
          console.error("Erro ao enviar formulário:", error);
          this.showSnackbar("Erro ao cadastrar item", "error");
        }
      }
    },
    async atualizarUnidade(item) {
      try {
        await atualizarUnidade(item.id, item);
        this.showSnackbar("Item atualizado!", "success");
      } catch (error) {
        console.error("Erro ao atualizar o item:", error);
        this.showSnackbar("Erro ao atualizar o item", "error");
      }
    },
    getUnidade() {
      getUnidade()
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
