import {
  createStore
} from 'vuex';

export default createStore({
  state: {
    selectedItemId: null,
  },
  mutations: {
    setSelectedItemId(state, id) {
      state.selectedItemId = id;
    },
    clearSelectedItem(state) {
      state.selectedItemId = null;
    },
  },
  actions: {
    selectItem({
      commit
    }, id) {
      commit('setSelectedItemId', id);
    },
    clearSelectedItem({
      commit
    }) {
      commit('clearSelectedItem');
    },
  },
  getters: {
    getSelectedItemId: (state) => state.selectedItemId,
  },
});