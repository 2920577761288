<template>
  <form @submit.prevent="enviarForm">
    <div class="linha">
      <div class="grid-4">
        <div>
          <label>Categoria</label>
          <input type="text" v-model="nome" id="nome" required />
        </div>

        <div class="linha alinha-v">
          <input type="checkbox" v-model="isCategoriaPai" id="isCategoriaPai" @change="toggleCategoriaPai" />
          <label for="isCategoriaPai">Categoria</label>
        </div>

        <div>
          <label>Categoria</label>
          <select v-model="categoria_pai_id" :disabled="isCategoriaPai" required>
            <option v-for="categoria in categoriasSuperiores" :key="categoria.id" :value="categoria.id">
              {{ categoria.nome }}
            </option>
          </select>
        </div>

        <div>
          <label>Código</label>
          <input type="number" maxlength="3" v-model="codigo" id="codigo" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required />
        </div>

        <div>
          <label>ARRUMAR</label>
          <select v-model="finalidadeSelecionada" @change="finalidadeChanged" required>
            <option value="" disabled selected>Selecione</option>
            <option v-for="item in finalidadeLista" :key="item.code" :value="item.code">{{ item.label }}</option>
          </select>
        </div>

        <div>
          <label>&zwnj;</label>
          <button class="acao-secundaria" type="submit">Adicionar</button>
        </div>
      </div>
    </div>
  </form>
  <br />
  <table class="tabela">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Categoria</th>
        <th>Código Padrão</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in paginatedItems" :key="item.id">
        <td>{{ item.nome }}</td>
        <td>{{ item.nome_categoria_pai }}</td>
        <td>{{ item.codigo }}</td>
        <td>
          <div class="linha alinha-v alinha-centro">
            <button @click="excluirItem(item.id)" class="acao-secundaria">Excluir</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
  </div>

  <confirmation-dialog v-model="dialogExcluir" title="Confirmar Exclusão" message="Você tem certeza que deseja excluir este item?" @confirm="confirmarExclusao" @cancel="dialogExcluir = false" />

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { getCategoria, getCategoriaSuperior, gravarCategoria, excluirCategoria } from "../../services/api";
import ConfirmationDialog from "../ConfirmationDialog.vue";

export default {
  name: "CategoriasComponent",
  components: {
    ConfirmationDialog,
  },

  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogExcluir: false,
      itemParaExcluir: null,
      items: [],
      categoriasSuperiores: [],
      perPage: 10,
      nome: "",
      categoria_pai_id: null,
      codigo: "",
      isCategoriaPai: false,
      currentPage: 1,
      localSearch: "",
      snackbarVisible: false,
      snackbarColor: "success",
      snackbarText: "",
    };
  },
  watch: {
    search(val) {
      this.localSearch = val;
      this.filterItems();
    },
  },
  computed: {
    filteredItems() {
      if (this.search) {
        return this.items.filter((item) => {
          return (
            item.nome.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.nome_categoria_pai && item.nome_categoria_pai.toLowerCase().includes(this.search.toLowerCase())) ||
            (item.codigo && item.codigo.toString().includes(this.search))
          );
        });
      }
      return this.items;
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredItems.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.perPage);
    },
  },
  mounted() {
    this.loadData();
    this.getCategoriasSuperiores();
  },
  methods: {
    loadData() {
      this.getData();
    },

    filterItems() {},

    emitSearch() {
      this.$emit("update:search", this.localSearch);
    },

    toggleCategoriaPai() {
      if (this.isCategoriaPai) {
        this.categoria_pai_id = null;
      }
    },
    showSnackbar(message, color = "success") {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => (this.snackbarVisible = false), 3000);
    },
    excluirItem(itemId) {
      this.itemParaExcluir = itemId;
      this.dialogExcluir = true;
    },
    async confirmarExclusao() {
      if (this.itemParaExcluir !== null) {
        try {
          const response = await excluirCategoria(this.itemParaExcluir);
          const categoriasExcluidas = response.data.categoriasExcluidas;

          this.items = this.items.filter((item) => !categoriasExcluidas.some((cat) => cat.id === item.id));

          await this.getCategoriasSuperiores();

          this.dialogExcluir = false;
          this.itemParaExcluir = null;
          this.showSnackbar("Categorias excluídas com sucesso!", "success");
        } catch (error) {
          console.error("Erro ao excluir o item:", error);
          this.showSnackbar("Erro ao excluir item", "error");
        }
      }
    },
    async enviarForm() {
      if (this.nome && (this.isCategoriaPai || (!this.isCategoriaPai && this.categoria_pai_id && this.codigo))) {
        const dadosFormulario = {
          nome: this.nome,
          codigo: this.codigo,
        };
        if (!this.isCategoriaPai) {
          dadosFormulario.categoria_pai = this.categoria_pai_id;
        }
        try {
          const response = await gravarCategoria(dadosFormulario);
          const novoItem = response.data;
          this.items.unshift(novoItem);
          this.nome = "";
          this.categoria_pai_id = null;
          this.codigo = "";
          this.isCategoriaPai = false;

          // Atualiza a lista de categorias superiores
          await this.getCategoriasSuperiores();

          this.showSnackbar("Item cadastrado!", "success");
        } catch (error) {
          console.error("Erro ao enviar formulário:", error);
          this.showSnackbar("Erro ao cadastrar item", "error");
        }
      }
    },
    async getCategoriasSuperiores() {
      try {
        const response = await getCategoriaSuperior();
        this.categoriasSuperiores = response.data;
      } catch (error) {
        console.error("Erro ao buscar categorias superiores:", error);
      }
    },
    async getData() {
      try {
        const response = await getCategoria();
        // Adiciona o nome da categoria pai para cada subcategoria
        const categorias = response.data;
        const flattenCategorias = [];
        categorias.forEach((categoria) => {
          categoria.subCategoria.forEach((subCat) => {
            subCat.nome_categoria_pai = categoria.nome;
            flattenCategorias.push(subCat);
          });
          flattenCategorias.push(categoria);
        });
        this.items = flattenCategorias;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
