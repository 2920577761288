<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>Notificar Ativo</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <label>Motivo</label>
          <select v-model="selectedMotivo" @change="updateSelectedMotivo">
            <option disabled value="">Selecione</option>
            <option v-for="motivo in motivos" :key="motivo.id" :value="motivo.id">
              {{ motivo.nome }}
            </option>
          </select>
          <textarea style="margin-top: 10px" v-model="descricao" label="Descrição"></textarea>
        </v-form>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" text @click="dialog = false">Cancelar</button>
        <button class="acao-secundaria" @click="confirmarNotificacao">Confirmar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="confirmarDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">Aviso de Recebimento</v-card-title>
      <v-card-text>
        <div class="">
          Você deseja aceitar o recebimento do <br />
          <strong>{{ selectedItem.codigo }} - {{ selectedItem.descricao }}?</strong>
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="salvarMovimentacao(false)">Recusar</button>
        <button class="acao-secundaria" @click="salvarMovimentacao(true)">Aceitar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <table class="tabela">
    <thead>
      <tr>
        <th>Código</th>
        <th>Descrição</th>
        <th>Status</th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in paginatedItems" :key="item.id">
        <td>{{ item.codigo }}</td>
        <td>{{ item.descricao }}</td>

        <td class="white-space: nowrap pointer" v-if="item.status === 'Aguardando Aceitação'" @click="confirmarRecebimento(item)">{{ item.status }}</td>
        <td v-else>
          <div v-if="item.status !== 'Em manutenção'">{{ item.status }}</div>
          <div v-else>{{ item.protocolo_codigo }}</div>
        </td>
        <td>
          <button class="acao-secundaria" @click.stop="notificarAtivo(item)">Notificar</button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
  </div>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { getAtivosUsuario, getMotivoAlerta, gravarNotificacaoAtivo, movimentarAtivo } from "@/services/api";
import { sso } from "roboflex-thalamus-sso-lib";
export default {
  name: "AtivosComponent",
  data() {
    return {
      items: [],
      itemsPerPage: 10,
      currentPage: 1,
      confirmarDialogVisible: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      usuario_id: null,
      dialog: false,
      selectedMotivo: "",
      currentItem: {},
      motivos: [],
      descricao: "",
      selectedItem: null,
    };
  },

  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },

  props: {
    search: {
      type: String,
      default: "",
    },
  },

  watch: {
    search: "filterItems",
  },

  computed: {
    filteredItems() {
      if (this.search) {
        return this.items.filter((item) => {
          return (
            item.descricao.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.descricao && item.descricao.toLowerCase().includes(this.search.toLowerCase())) ||
            (item.status && item.status.toLowerCase().includes(this.search)) ||
            (item.codigo && item.codigo.toString().includes(this.search))
          );
        });
      }
      return this.items;
    },

    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async confirmarRecebimento(item) {
      this.selectedItem = item;
      this.confirmarDialogVisible = true;
    },

    fecharConfirmarDialog() {
      this.confirmarDialogVisible = false;
    },

    async loadData() {
      try {
        const response = await getAtivosUsuario(this.usuario_id);
        this.items = response.data;
      } catch (error) {
        console.error("Erro ao carregar ativos:", error);
      }
    },
    async carregarMotivos() {
      try {
        const response = await getMotivoAlerta();
        this.motivos = response.data.alertas;
      } catch (error) {
        console.error("Erro ao carregar motivos:", error);
      }
    },
    filterItems() {
      this.currentPage = 1;
    },
    emitSearch() {
      this.$emit("update:search", this.localSearch);
    },
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },
    notificarAtivo(item) {
      this.currentItem = item;
      this.dialog = true;
      this.carregarMotivos();
    },

    async confirmarNotificacao() {
      if (!this.selectedMotivo || !this.descricao) {
        this.showSnackbar("Por favor, preencha todos os campos.", "error");
        return;
      }

      try {
        const dados = {
          ativo_id: this.currentItem.id,
          motivo_id: this.selectedMotivo,
          notificante_id: this.usuario_id,
          obsNotificante: this.descricao,
        };
        await gravarNotificacaoAtivo(dados);
        this.dialog = false;
        this.showSnackbar("Notificação enviada com sucesso!", "success");
      } catch (error) {
        console.error("Erro ao gravar notificação:", error);
        this.showSnackbar("Erro ao enviar notificação.", "error");
      }
    },

    async salvarMovimentacao(aceitar) {
      try {
        const movimentacaoDados = {
          ativo_id: this.selectedItem.id,
          usuario_id: this.usuario_id,
          aceito: aceitar ? 1 : 0,
        };

        await movimentarAtivo(movimentacaoDados);

        this.showSnackbar(aceitar ? "Ativo aceito" : "Ativo recusado", "success");
        this.fecharConfirmarDialog();
        this.loadData();
      } catch (error) {
        console.error("Erro ao movimentar ativo:", error);
        this.showSnackbar("Erro ao movimentar ativo", "error");
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
