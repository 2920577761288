import { createRouter, createWebHashHistory } from "vue-router";
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissaoService";

import HomePatrimonioView from "../views/Home/HomePatrimonioView.vue";
import HomeMateriaisView from "../views/Home/HomeMateriaisView.vue";
import HomeGestaoView from "../views/Home/HomeGestaoView.vue";

import CadastroAuxiliarView from "../views/CadastroAuxiliar/CadastroAuxiliarView.vue";

import SolicitacaoView from "@/views/Material/SolicitacaoView.vue";
import AprovacaoView from "@/views/Material/AprovacaoView.vue";
import SeparacaoView from "@/views/Material/SeparacaoView.vue";

import MinhaAreaView from "@/views/MinhaAreaView.vue";
import CadastroAtivosView from "@/views/Patrimonio/CadastroAtivosView.vue";
import ListaAtivosView from "@/views/Patrimonio/ListaAtivosView.vue";
import AtivosOciososView from "@/views/Patrimonio/AtivosOciososView.vue";
import AlertasView from "@/views/Patrimonio/AlertasView.vue";

import HistoricoView from "@/views/Patrimonio/HistoricoView.vue";

async function guardPermissao(to, from, next) {
  const permissoes = await getPermissao();
  const funcionalidadeNecessaria = to.meta.funcionalidadeId;

  if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
    next({ name: "HomePatrimonio" });
  } else {
    next();
  }
}

function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

function guardPermissaoRoute(to, from, next) {
  guardMyroute(to, from, (guardResultado) => {
    if (guardResultado !== undefined) {
      next(guardResultado);
    } else {
      guardPermissao(to, from, next);
    }
  });
}

const routes = [
  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },
  {
    path: "/",
    name: "HomePatrimonio",
    component: HomePatrimonioView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/material",
    name: "HomeMateriais",
    component: HomeMateriaisView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/gestao",
    name: "HomeGestao",
    component: HomeGestaoView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/solicitar",
    name: "Solicitacao",
    component: SolicitacaoView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 69 },
  },
  {
    path: "/aprovar",
    name: "Aprovacao",
    component: AprovacaoView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 70 },
  },
  {
    path: "/separar",
    name: "Separacao",
    component: SeparacaoView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 71 },
  },
  {
    path: "/ativos/ativos-nao-movimentados",
    name: "AtivosOciosos",
    component: AtivosOciososView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 74 },
  },
  {
    path: "/ativos/cadastro-ativos/:id?",
    name: "CadastroAtivos",
    component: CadastroAtivosView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 75 },
  },
  {
    path: "/ativos/lista",
    name: "ListaAtivos",
    component: ListaAtivosView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 76 },
  },
  {
    path: "/ativos/alertas",
    name: "Alertas",
    component: AlertasView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 77 },
  },
  {
    path: "/ativos/historico",
    name: "HistoricoView",
    component: HistoricoView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 78 },
  },
  {
    path: "/minha-area",
    name: "MinhaAreaView",
    component: MinhaAreaView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 72 },
  },
  {
    path: "/cadastro-auxiliar",
    name: "CadastroAuxiliarView",
    component: CadastroAuxiliarView,
    beforeEnter: guardPermissaoRoute,
    meta: { funcionalidadeId: 73 },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
