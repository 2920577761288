<template>
  <v-dialog v-model="loginDialogVisible" max-width="400">
    <v-card>
      <v-card-title class="headline">Confirmação de Associação</v-card-title>
      <v-card-text>
        <div>
          <label for="unidadeSelecionada">Selecionar Unidade</label>
          <select id="unidadeSelecionada" v-model="unidadeSelecionada" required>
            <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">{{ unidade.local_nome }}</option>
          </select>
        </div>

        <div>
          <label>Local Físico</label>
          <select v-model="localFisico_id" class="mt-2" required>
            <option v-for="local in locaisFiltrados" :key="local.id" :value="local.id">
              {{ local.nome }}
            </option>
          </select>
        </div>

        <br />
        <div>
          <label for="loginUsuario">Usuário</label>
          <input id="loginUsuario" v-model="loginUsuario" type="text" required @keyup.enter="movimentarAtivo" />
        </div>
        <br />
        <div>
          <label for="loginSenha">Senha</label>
          <input id="loginSenha" v-model="loginSenha" type="password" required @keyup.enter="movimentarAtivo" />
        </div>
      </v-card-text>
      <v-spacer></v-spacer>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="closeDialog">Cancelar</button>
        <button class="acao-secundaria" @click="movimentarAtivo">Confirmar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="detalhesDialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">Detalhes do Ativo</v-card-title>
      <v-card-text>
        <div><strong>Código:</strong> {{ selectedItem.codigo }}</div>
        <div><strong>Nome:</strong> {{ selectedItem.descricao }}</div>
        <div><strong>Valor:</strong>{{ formatarValorReal(selectedItem.valor) }}</div>
        <div><strong>Unidade:</strong> {{ selectedItem.unidade_nome }}</div>
        <div><strong>Marca:</strong> {{ selectedItem.marca }}</div>
        <div><strong>Modelo:</strong> {{ selectedItem.modelo }}</div>
        <div><strong>IMEI:</strong> {{ selectedItem.imei }}</div>
        <div><strong>Código de Barras:</strong> {{ selectedItem.codigoBarras }}</div>
        <div><strong>Número de Série:</strong> {{ selectedItem.nrSerie }}</div>
        <div><strong>Fornecedor:</strong> {{ selectedItem.fornecedor_nome }}</div>
        <div><strong>Vida Útil:</strong> {{ selectedItem.vidaUtil }}</div>
        <div><strong>Data Fim da Garantia:</strong> {{ selectedItem.dtFinalGarantia }}</div>
        <div><strong>Complemento:</strong> {{ selectedItem.complemento }}</div>
        <div class="alinha-v alinha-centro">
          <img :src="qrCodeUrl" />
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button class="acao-secundaria" @click="detalhesDialogVisible = false">Fechar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Ativos não Movimentados</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <table class="tabela">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Valor</th>
            <th>Unidade</th>
            <th>Status</th>
            <th>Responsável</th>
            <!-- <th>Ações</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedItems" :key="item.id" @click="mostrarDetalhes(item)">
            <td>{{ item.codigo }}</td>
            <td>{{ item.descricao }}</td>
            <td>{{ formatarValorReal(item.valor) }}</td>
            <td>{{ item.unidade_nome }}</td>
            <td style="white-space: nowrap">
              <div v-if="item.status !== 'Em manutenção'">{{ item.status }}</div>
              <div v-else>{{ item.protocolo_codigo }}</div>
            </td>
            <td>Almoxarifado</td>
            <!--             <td>
              <button class="acao-secundaria" @click.stop="abrirModal(item)">Movimentar Ativo</button>
            </td> -->
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
      </div>
    </div>
  </div>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { getListarAtivos, getLocalFisico, movimentarAtivo, getUnidade } from "@/services/api";
import { formatarValorReal } from "@/utils/funcoesUtils";
import { sso } from "roboflex-thalamus-sso-lib";
export default {
  name: "AtivosOciososView",
  data() {
    return {
      locais: [],
      localFisico_id: "",
      items: [],
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      loginDialogVisible: false,
      loginUsuario: "",
      loginSenha: "",
      selectedItem: null,
      detalhesDialogVisible: false,
      unidadeSelecionada: null,
      unidades: [],
      usuario_id: null,
    };
  },
  computed: {
    qrCodeUrl() {
      return `${process.env.VUE_APP_ROOT_STORAGE}${this.selectedItem.qrcode_path}`;
    },
    locaisFiltrados() {
      return this.locais.filter((local) => local.local_id === this.unidadeSelecionada);
    },
    filteredItems() {
      const searchTerm = this.search.toLowerCase();
      return this.items.filter((item) => {
        const codigo = item.codigo ? item.codigo.toLowerCase() : "";
        const descricao = item.descricao ? item.descricao.toLowerCase() : "";
        const valor = item.valor ? item.valor.toString() : "";
        const unidade_nome = item.unidade_nome ? item.unidade_nome.toLowerCase() : "";

        return codigo.includes(searchTerm) || descricao.includes(searchTerm) || valor.includes(searchTerm) || unidade_nome.includes(searchTerm);
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.carregarAtivos();
    this.getLocais();
    this.carregarUnidades();
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },

  methods: {
    async carregarUnidades() {
      try {
        const response = await getUnidade();
        this.unidades = response.data;
      } catch (error) {
        console.error("Erro ao carregar unidades:", error);
      }
    },
    formatarValorReal,
    abrirModal(item) {
      this.selectedItem = item;
      this.loginDialogVisible = true;
    },
    mostrarDetalhes(item) {
      this.selectedItem = item;
      this.detalhesDialogVisible = true;
    },
    async movimentarAtivo() {
      if (this.loginUsuario && this.loginSenha && this.localFisico_id) {
        try {
          const payload = {
            usuario_id: this.usuario_id,
            ativo_id: this.selectedItem.id,
            localFisico_id: this.localFisico_id,
            email: this.loginUsuario,
            password: this.loginSenha,
          };
          await movimentarAtivo(payload);
          this.showSnackbar("Ativo movimentado com sucesso", "success");
          this.carregarAtivos();
        } catch (error) {
          console.error("Erro ao movimentar ativo:", error);
          this.showSnackbar("Erro ao movimentar ativo", "error");
        } finally {
          this.closeDialog();
        }
      } else {
        this.showSnackbar("Todos os campos são obrigatórios.", "error");
      }
    },
    closeDialog() {
      this.loginDialogVisible = false;
      this.localFisico_id = "";
      this.loginUsuario = "";
      this.loginSenha = "";
      this.selectedItem = null;
    },
    async carregarAtivos() {
      try {
        const response = await getListarAtivos();
        this.items = response.data.filter((item) => item.responsavel_id === null);
      } catch (error) {
        console.error("Erro ao carregar ativos:", error);
      }
    },
    async getLocais() {
      try {
        const response = await getLocalFisico();
        this.locais = response.data;
      } catch (error) {
        console.error("Erro ao carregar locais físicos:", error);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },
  },
};
</script>
