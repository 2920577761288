<template>
  <v-dialog v-model="separationDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">Separar Material</v-card-title>
      <v-card-text>
        <v-textarea v-model="separationObservation" label="Observação" rows="3" required></v-textarea>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" text @click="separationDialogVisible = false">Cancelar</button>
        <button class="acao-secundaria" text @click="separateWithObservation">Separar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="deliveryDialogVisible" max-width="500">
    <v-card>
      <v-card-title class="headline">Receber Material</v-card-title>
      <v-card-text>
        <v-text-field v-model="qtRecebida" label="Qtd Recebida" type="number" required></v-text-field>
        <v-textarea v-model="obsEntrega" label="Observação" rows="3" required></v-textarea>
        <v-text-field v-model="loginUsuario" label="Email" required></v-text-field>
        <v-text-field v-model="loginSenha" label="Senha" type="password" required @keyup.enter="deliverWithObservation"></v-text-field>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button style="margin-right: 10px" class="acao-secundaria" @click="deliveryDialogVisible = false">Cancelar</button>
        <button class="acao-secundaria" @click="deliverWithObservation">Receber</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <v-dialog v-model="detailsDialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">Detalhes da Solicitação</v-card-title>
      <v-card-text>
        <div v-if="solicitacaoDetalhes">
          <p>
            <strong>Código:</strong>
            {{ solicitacaoDetalhes.codigo }}
          </p>
          <p>
            <strong>Finalidade:</strong>
            {{ solicitacaoDetalhes.finalidade }}
          </p>
          <p>
            <strong>Solicitante:</strong>
            {{ solicitacaoDetalhes.solicitante_nome }}
          </p>
          <p>
            <strong>Setor:</strong>
            {{ solicitacaoDetalhes.setor_id }}
          </p>
          <p>
            <strong>Data da solicitação:</strong>
            {{ formatarData(solicitacaoDetalhes.dtCriacao) }}
          </p>
          <p>
            <strong>Justificativa:</strong>
            {{ solicitacaoDetalhes.justificativa }}
          </p>
          <v-divider></v-divider>
          <h4>Material</h4>
          <v-list dense>
            <v-list-item v-for="material in solicitacaoDetalhes.materiais" :key="material.solicitacaoMaterial_id">
              <v-list-item-content>
                <v-list-item-title>{{ material.material_desc }}</v-list-item-title>
                <v-list-item-subtitle>Quantidade: {{ material.qt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div v-else>
          <p>Carregando...</p>
        </div>
      </v-card-text>
      <div class="alinha-v alinha-centro">
        <button class="acao-secundaria" text @click="detailsDialogVisible = false">Fechar</button>
      </div>
      <br />
    </v-card>
  </v-dialog>

  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Separação de Material</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="tags m-b">
        <div v-for="tabItem in tabItems" :key="tabItem.value">
          <a :class="{ ativo: tab === tabItem.value, active: tab === tabItem.value }" @click.prevent="tab = tabItem.value">
            {{ tabItem.text }}
          </a>
        </div>
      </div>

      <div v-for="tabItem in tabItems" :key="tabItem.value" :class="{ 'tab-pane': true, active: tab === tabItem.value }">
        <div v-if="tab === tabItem.value">
          <table class="tabela">
            <thead>
              <tr>
                <th>Código</th>
                <th>Finalidade</th>
                <th>Material</th>
                <th>Qtd Aprovada</th>
                <th v-if="tab === 1">Qtd Recebida</th>
                <th>Solicitante</th>
                <th>Data da Separação</th>
                <th v-if="tab === 1">Data Recebimento</th>
                <th v-if="tab === 0">Separar</th>
                <th v-if="tab === 0">Entregar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="material in filteredItems" :key="material.solicitacaoMaterial_id" @click="openDetailsDialog(material.solicitacao_id, material.solicitacaoMaterial_id)">
                <td>{{ material.codigo }}</td>
                <td>{{ material.finalidade }}</td>
                <td>{{ material.material_desc }}</td>
                <td>{{ material.qtAprovada }}</td>
                <td v-if="tab === 1">{{ material.qtRecebida }}</td>
                <td>{{ material.solicitante_nome }}</td>
                <td>{{ formatarData(material.dtSeparacao) }}</td>
                <td v-if="tab === 1">{{ formatarData(material.dtRecebimento) }}</td>
                <td v-if="tab === 0">
                  <button v-if="material.dtSeparacao === null" class="acao-secundaria" @click.stop="openSeparationDialog(material)">Separar</button>
                  <button v-else disabled class="acao-secundaria">Separar</button>
                </td>
                <td v-if="tab === 0">
                  <button class="acao-secundaria" @click.stop="openDeliveryDialog(material)">Entregar</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <nav v-if="tab === tabItem.value" aria-label="Page navigation">
          <div class="pagination">
            <div :class="{ disabled: currentPage === 1 }">
              <a href="#" @click.prevent="prevPage">Anterior</a>
            </div>
            <div v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </div>
            <div :class="{ disabled: currentPage === totalPages }">
              <a href="#" @click.prevent="nextPage">Próxima</a>
            </div>
          </div>
        </nav>
      </div>

      <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { listarMateriais, alterarStatusMaterial, detalharSolicitacao } from "@/services/api";
import { formatarData } from "@/utils/funcoesUtils";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "SeparacaoView",
  data() {
    return {
      search: "",
      items: [],
      perPage: 10,
      currentPage: 1,
      tab: 0,
      tabItems: [
        { text: "Pendentes", value: 0, tipo: "nao-recebidos" },
        { text: "Entregues", value: 1, tipo: "recebidos" },
      ],
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "",
      separationDialogVisible: false,
      separationObservation: "",
      deliveryDialogVisible: false,
      detailsDialogVisible: false,
      solicitacaoDetalhes: null,
      obsEntrega: "",
      qtRecebida: "",
      loginUsuario: "",
      loginSenha: "",
      dataLoaded: [false, false],
      actionItem: null,
      usuario_id: null,
    };
  },
  computed: {
    filteredItems() {
      const filtered = this.items.filter(
        (item) =>
          item.codigo.toLowerCase().includes(this.search.toLowerCase()) ||
          item.finalidade.toLowerCase().includes(this.search.toLowerCase()) ||
          item.material_desc.toLowerCase().includes(this.search.toLowerCase()) ||
          item.solicitante_nome.toLowerCase().includes(this.search.toLowerCase())
      );
      return filtered.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    totalPages() {
      const filteredLength = this.items.filter(
        (item) =>
          item.codigo.toLowerCase().includes(this.search.toLowerCase()) ||
          item.finalidade.toLowerCase().includes(this.search.toLowerCase()) ||
          item.material_desc.toLowerCase().includes(this.search.toLowerCase()) ||
          item.solicitante_nome.toLowerCase().includes(this.search.toLowerCase())
      ).length;
      return Math.ceil(filteredLength / this.perPage);
    },
  },
  watch: {
    tab(newTab, oldTab) {
      if (newTab !== oldTab) {
        this.currentPage = 1;
        this.dataLoaded[newTab] = false;
        this.getData(this.tabItems[newTab].tipo);
      }
    },
  },
  mounted() {
    this.getData(this.tabItems[this.tab].tipo);
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },
  methods: {
    formatarData,
    getData(tipo) {
      if (!this.dataLoaded[this.tab]) {
        listarMateriais({ tipo })
          .then((response) => {
            this.items = response.data.flatMap((solicitacao) =>
              Array.isArray(solicitacao.materiais)
                ? solicitacao.materiais.map((material) => ({
                    ...solicitacao,
                    ...material,
                  }))
                : Object.values(solicitacao.materiais).map((material) => ({
                    ...solicitacao,
                    ...material,
                  }))
            );
            this.dataLoaded[this.tab] = true;
          })
          .catch((error) => {
            console.error("Erro ao buscar dados:", error);
            this.dataLoaded[this.tab] = false;
          });
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    openSeparationDialog(item) {
      this.actionItem = item;
      this.separationObservation = "";
      this.separationDialogVisible = true;
    },
    openDeliveryDialog(item) {
      this.actionItem = item;
      this.obsEntrega = "";
      this.loginUsuario = "";
      this.loginSenha = "";
      this.qtRecebida = "";
      this.deliveryDialogVisible = true;
    },
    separateWithObservation() {
      this.separarMaterial(this.actionItem, this.separationObservation);
      this.separationDialogVisible = false;
    },
    deliverWithObservation() {
      if (this.loginUsuario && this.loginSenha) {
        this.entregarMaterial(this.actionItem, this.obsEntrega, this.loginUsuario, this.loginSenha, this.qtRecebida);
        this.deliveryDialogVisible = false;
      } else {
        this.showSnackbar("Email e senha são obrigatórios.", "error");
      }
    },
    separarMaterial(item, observacao) {
      const data = {
        solicitacaoMaterialStatus_id: item.solicitacaoMaterialStatus_id,
        separador_id: this.usuario_id,
        obsSeparacao: observacao,
      };
      alterarStatusMaterial(data)
        .then((response) => {
          const dtSeparacao = response.data.dtSeparacao;
          item.separador_id = this.usuario_id;
          item.dtSeparacao = dtSeparacao;
          item.obsSeparacao = observacao;
          this.showSnackbar("Material separado com sucesso!", "success");
        })
        .catch(() => {
          this.showSnackbar("Erro ao separar o material", "error");
        });
    },
    async entregarMaterial(item, observacao, email, password, qtRecebida) {
      const data = {
        solicitacaoMaterialStatus_id: item.solicitacaoMaterialStatus_id,
        obsRecebimento: observacao,
        email: email,
        password: password,
        qtRecebida: qtRecebida,
      };
      try {
        const response = await alterarStatusMaterial(data);
        const solicitacaoMaterialId = response.data.solicitacaoMaterial_id;
        this.removeMaterialFromPending(item.solicitacao_id, solicitacaoMaterialId);
        this.showSnackbar("Material entregue com sucesso!", "success");
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          this.showSnackbar(error.response.data.error, "error");
        } else {
          this.showSnackbar("Erro ao entregar o material.", "error");
        }
      }
    },
    removeMaterialFromPending(solicitacaoId, materialId) {
      const materialIndex = this.items.findIndex((item) => item.solicitacaoMaterial_id === materialId);
      if (materialIndex !== -1) {
        this.items.splice(materialIndex, 1);
      }
    },
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbarVisible = true;
    },
    openDetailsDialog(solicitacao_id, solicitacaoMaterial_id) {
      this.detailsDialogVisible = true;
      detalharSolicitacao({ solicitacao_id, solicitacaoMaterial_id })
        .then((response) => {
          this.solicitacaoDetalhes = response.data[0];
        })
        .catch((error) => {
          console.error("Erro ao buscar detalhes da solicitação:", error);
          this.solicitacaoDetalhes = null;
        });
    },
  },
};
</script>
