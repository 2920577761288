<template>
  <aside>
    <div class="titulo margem efeito">
      <div class="m-icone">
        <a href="#" class="icone-menu" title="Menu" @click.prevent="toggleMenu"></a>
      </div>
    </div>

    <div class="separador margem">
      <div class="m-icone">
        <router-link to="/" :class="{ 'icone-patrimonio': true, ativo: activeMenu === 'Patrimonio' }" title="Patrimônio" id="Patrimonio" @click="abaMenu('Patrimonio')"></router-link>
        <router-link to="/material" :class="{ 'icone-contrato': true, ativo: activeMenu === 'Material' }" title="Material" id="Material" @click="abaMenu('Material')"></router-link>
        <router-link to="/gestao" :class="{ 'icone-gestao': true, ativo: activeMenu === 'Gestao' }" title="Gestão" id="Gestao" @click="abaMenu('Gestao')"></router-link>
      </div>
    </div>

    <nav id="menuMaterial" class="nav-maior separador" :class="{ none: activeMenu !== 'Material' }">
      <router-link v-if="permissoes.includes(69)" to="/solicitar" active-class="ativo">Solicitar</router-link>
      <router-link v-if="permissoes.includes(70)" to="/aprovar" active-class="ativo">Aprovar</router-link>
      <router-link v-if="permissoes.includes(71)" to="/separar" active-class="ativo">Separar</router-link>
    </nav>

    <nav id="menuPatrimonio" class="nav-maior separador" :class="{ none: activeMenu !== 'Patrimonio' }">
      <router-link v-if="permissoes.includes(74)" to="/ativos/ativos-nao-movimentados" active-class="ativo">Ativos não Movimentados</router-link>
      <router-link v-if="permissoes.includes(75)" to="/ativos/cadastro-ativos" active-class="ativo">Cadastrar Ativos</router-link>
      <router-link v-if="permissoes.includes(76)" to="/ativos/lista" active-class="ativo">Lista de Ativos</router-link>
      <router-link v-if="permissoes.includes(77)" to="/ativos/alertas" active-class="ativo">Alertas</router-link>
      <router-link v-if="permissoes.includes(78)" to="/ativos/historico" active-class="ativo">Histórico dos Ativos</router-link>
    </nav>

    <nav id="menuGestao" class="nav-maior separador" :class="{ none: activeMenu !== 'Gestao' }">
      <router-link v-if="permissoes.includes(72)" to="/minha-area" active-class="ativo">Minha Área</router-link>
      <router-link v-if="permissoes.includes(73)" to="/cadastro-auxiliar" active-class="ativo">Cadastros Auxiliares</router-link>
    </nav>
  </aside>
</template>

<script>
import { getPermissao } from "@/services/permissaoService";

export default {
  data() {
    return {
      permissoes: [],
      activeMenu: "Patrimonio",
    };
  },
  async created() {
    this.permissoes = await getPermissao();
  },
  methods: {
    abaMenu(menu) {
      this.activeMenu = menu;
    },
    toggleMenu() {
      document.getElementById("main").classList.toggle("esconde");
    },
  },
};
</script>
