<template>
  <div class="titulo">
    <div class="margem container">
      <div class="m-icone direita">
        <div class="pesquisa">
          <input type="text" v-model="search" placeholder="Pesquise Aqui" @input="filterItems" />
          <a href="#" class="icone-pesquisa" title="Pesquise"></a>
        </div>
      </div>
      <h2>Cadastros Auxiliares</h2>
    </div>
  </div>

  <div class="margem container">
    <div class="bloco margem">
      <div class="tags m-b">
        <a :class="{ ativo: selectedTab === 0, active: selectedTab === 0 }" @click.stop="changeTab(0)">Status Ativo</a>
        <a :class="{ ativo: selectedTab === 1, active: selectedTab === 1 }" @click.stop="changeTab(1)">Locais</a>
        <a :class="{ ativo: selectedTab === 2, active: selectedTab === 2 }" @click.stop="changeTab(2)">Unidade</a>
        <a :class="{ ativo: selectedTab === 3, active: selectedTab === 3 }" @click.stop="changeTab(3)">Motivos Alertas</a>
        <a :class="{ ativo: selectedTab === 4, active: selectedTab === 4 }" @click.stop="changeTab(4)">Categorias</a>
      </div>

      <StatusAtivosComponent v-show="selectedTab === 0" v-if="selectedTab === 0" ref="statusAtivosComponent" :search="search" @update:search="updateSearch" />
      <LocaisComponent v-show="selectedTab === 1" v-if="selectedTab === 1" ref="locaisComponent" :search="search" @update:search="updateSearch" />
      <UnidadeComponent v-show="selectedTab === 2" v-if="selectedTab === 2" ref="unidadeComponent" :search="search" @update:search="updateSearch" />
      <MotivosAlertasComponent v-show="selectedTab === 3" v-if="selectedTab === 3" ref="motivosAlertasComponent" :search="search" @update:search="updateSearch" />
      <CategoriasComponent v-show="selectedTab === 4" v-if="selectedTab === 4" ref="categoriasComponent" :search="search" @update:search="updateSearch" />
    </div>
  </div>
</template>

<script>
import StatusAtivosComponent from "@/components/CadastroAuxiliar/StatusAtivosComponent.vue";
import LocaisComponent from "@/components/CadastroAuxiliar/LocaisComponent.vue";
import UnidadeComponent from "@/components/CadastroAuxiliar/UnidadeComponent.vue";
import MotivosAlertasComponent from "@/components/CadastroAuxiliar/MotivosAlertasComponent.vue";
import CategoriasComponent from "@/components/CadastroAuxiliar/CategoriasComponent.vue";

export default {
  name: "CadastroAuxiliarView",
  components: {
    StatusAtivosComponent,
    LocaisComponent,
    UnidadeComponent,
    MotivosAlertasComponent,
    CategoriasComponent,
  },
  data() {
    return {
      selectedTab: 0,
      search: "",
    };
  },
  methods: {
    changeTab(newTab) {
      this.selectedTab = newTab;
      this.$nextTick(() => {
        this.filterItems();
        switch (newTab) {
          case 0:
            if (this.$refs.statusAtivosComponent) {
              this.$refs.statusAtivosComponent.loadData();
            }
            break;
          case 1:
            if (this.$refs.locaisComponent) {
              this.$refs.locaisComponent.loadData();
            }
            break;
          case 2:
            if (this.$refs.unidadeComponent) {
              this.$refs.unidadeComponent.loadData();
            }
            break;
          case 3:
            if (this.$refs.motivosAlertasComponent) {
              this.$refs.motivosAlertasComponent.loadData();
            }
            break;
          case 4:
            if (this.$refs.categoriasComponent) {
              this.$refs.categoriasComponent.loadData();
            }
            break;
        }
      });
    },
    filterItems() {
      const currentComponent =
        this.$refs[
          {
            0: "statusAtivosComponent",
            1: "locaisComponent",
            2: "unidadeComponent",
            3: "motivosAlertasComponent",
            4: "categoriasComponent",
          }[this.selectedTab]
        ];

      if (currentComponent && currentComponent.filterItems) {
        currentComponent.filterItems(this.search);
      }
    },
    updateSearch(newSearch) {
      this.search = newSearch;
      this.filterItems();
    },
  },
};
</script>
