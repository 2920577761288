<template>
  <table class="tabela">
    <thead>
      <tr>
        <th>Código</th>
        <th>Finalidade</th>
        <th>Material</th>
        <th>Qtd Solicitada</th>
        <th>Qtd Aprovada</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in paginatedItems" :key="item.solicitacaoMaterial_id">
        <td>{{ item.codigo }}</td>
        <td>{{ item.finalidade }}</td>
        <td>{{ item.material_desc }}</td>
        <td>{{ item.qt }}</td>
        <td>{{ item.qtAprovada }}</td>
        <td>{{ item.status }}</td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1" class="acao-secundaria">Anterior</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="acao-secundaria">Próxima</button>
  </div>

  <v-snackbar v-model="snackbarVisible" :color="snackbarColor" timeout="3000">
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import { listarMateriais } from "@/services/api";
import { sso } from "roboflex-thalamus-sso-lib";
export default {
  name: "SolicitacaoComponent",
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: [],
      itemsPerPage: 10,
      currentPage: 1,
      snackbarVisible: false,
      snackbarColor: "",
      snackbarText: "",
      usuario_id: null,
    };
  },
  computed: {
    filteredItems() {
      if (this.search) {
        return this.items.filter((item) => {
          return (
            item.codigo.toString().includes(this.search) ||
            item.finalidade.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.material_desc && item.material_desc.toLowerCase().includes(this.search.toLowerCase())) ||
            item.qt.toString().includes(this.search) ||
            item.qtAprovada.toString().includes(this.search) ||
            item.status.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      return this.items;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.loadData();
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.usuario_id = usuarioLogado.id;
  },

  methods: {
    loadData() {
      const tipo = "todos";
      const usuario_id = this.usuario_id;
      listarMateriais({ tipo, usuario_id })
        .then((response) => {
          const transformedItems = [];
          response.data.forEach((solicitacao) => {
            if (Array.isArray(solicitacao.materiais)) {
              solicitacao.materiais.forEach((material) => {
                transformedItems.push({
                  ...solicitacao,
                  ...material,
                  solicitacaoMaterial_id: material.solicitacaoMaterial_id,
                  material_desc: material.material_desc,
                  qt: material.qt,
                  qtAprovada: material.qtAprovada,
                  aprovado: material.aprovado,
                  separador_id: material.separador_id,
                  dtSeparacao: material.dtSeparacao,
                  obsSeparacao: material.obsSeparacao,
                  receptor_id: material.receptor_id,
                  obsRecebimento: material.obsRecebimento,
                  dtRecebimento: material.dtRecebimento,
                });
              });
            }
          });
          this.items = transformedItems;
        })
        .catch((error) => {
          console.error("Erro ao buscar dados:", error);
          this.snackbarColor = "error";
          this.snackbarText = "Erro ao buscar dados";
          this.snackbarVisible = true;
        });
    },
    filterItems() {
      this.currentPage = 1;
    },
    emitSearch() {
      this.$emit("update:search", this.search);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  watch: {
    search: "filterItems",
  },
};
</script>
